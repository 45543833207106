import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Alert } from "react-native";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { GetAllCommunitiesResponse } from './types'
import { toast } from 'react-toastify';
import * as Yup from 'yup'
import { sendAPIRequest } from "../../../components/src/utils";
import { throttle } from 'throttle-debounce';
import { sendPostUpdatedMessage } from './Messages'
import { createRef } from "react";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  PostData: any;
  token: string;
  name: string;
  description: string;
  price: any;
  currency: string;
  category_id: string;
  image: any;
  uploadedImages: any;
  AllCategory: any[];
  id: any;
  refresh: boolean;
  file: any;
  profileImageData: any;
  selectedCategory: any;
  allCategories: any[];
  tagPostData: any[];
  showSuggetion: boolean;
  tagpostTextfield: string;
  postText: string;
  showModal: boolean;
  selectedFiles: File[];
  communities: any[];
  categories: any[];
  categoriesOptions: any[];
  subCategories: any[];
  selectedCommunities: any[];
  selectedSubCategory: any[];
  submittingPost: boolean;
  allSubCategories: any[];
  fileSizeWarning: string;
  communityPaginateCallId?: string;
  communitySearchCallId?: string;
  communityQuery: any;
  communityPagination?: any;
  currentPost?: any;
  loading?: any;
  initCommunities?: any;
  cursorPosition:number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PostCreationCommonController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiPostItemCallId: string = "";
  apiGetCategoryCallID: string = "";
  PostApiCallId: string = "";
  DeleteApiCallId: any;
  addpostApiCallId: any;
  updatePostApiCallId: any = "";

  apiGetAllCommunitiesCallId: string = "";
  apiGetAllCategoriesCallId: string = "";
  apiGetSubCategoriesCallId: string = "";
  apiCreatePostCallId: string = "";
  apiUpdatePostCallId: string = "";
  apiGetAllSubCategoriesCallId: string = "";
  currentPostCallId: string = "";
  postCreationApiCallId :string = "";
  setEditDefaultIntervalId?: any;
  textFieldRef: any;
  
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleSuggestionClick = this.handleSuggestionClick.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: (typeof localStorage!='undefined'&&localStorage.getItem('authToken')) || '',
      PostData: [],
      name: "",
      description: "",
      price: "",
      currency: "$",
      category_id: "",
      image: "",
      id: "",
      uploadedImages: [],
      AllCategory: [],
      file: "",
      refresh: false,
      profileImageData: {},
      selectedCategory: [],
      allCategories: [],
      postText: '',
      showModal: true,
      selectedFiles: [],
      communities: [],
      categories: [],
      categoriesOptions: [],
      subCategories: [], // categories shown in the dropdown
      selectedCommunities: [],
      selectedSubCategory: [],
      allSubCategories: [],
      submittingPost: false,
      fileSizeWarning: '',
      communityPaginateCallId: '',
      communitySearchCallId: '',
      communityQuery: '',
      tagPostData :[],
      showSuggetion: false,
      loading: false,
      tagpostTextfield : "",
      cursorPosition: 0, 
      // Customizable Area End
    };
    // Customizable Area Start
    this.textFieldRef = createRef<HTMLInputElement>();
    console.disableYellowBox = true;
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    var authTokenReq = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(authTokenReq);
  }

  async receive(from: string, message: Message) {  
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );  
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) { 
     if(apiRequestCallId === this.postCreationApiCallId){  
      this.setState({tagPostData : responseJson.accounts})

      if(responseJson.accounts && responseJson.accounts.length > 0){
        this.setState({showSuggetion : true})
      }
      else{
        this.setState({showSuggetion : false})
      }    
     }
    }
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const item = message.getData(
        getName(MessageEnum.PostDetailDataMessage)
      );    
    } else if (getName(MessageEnum.SessionResponseMessage) === message.id) { 
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token || localStorage.getItem('authToken') }, () => {
        this.tokenInitialized()
      });
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleRestAPIResponseMessage(message)
    }
    // Customizable Area End
  }

  
  // Customizable Area Start
  createPostCreation() {
      this.AddPostCreation();
  }

  goToItemDetails(item:any, isEdit:boolean) {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), isEdit ? "PostCreation" : "PostDetails" );

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );

    raiseMessage.addData(getName(MessageEnum.PostDetailDataMessage), item);
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    
    this.send(msg);
  }

  editNavigation = (item: any) => {
    this.goToItemDetails(item, true);
  };

  navigateToDetails = (item: any) => {
    this.goToItemDetails(item, false);
  };

  AddPostCreation(): boolean {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token
    };

    const attrs = {
      data: {
        attributes: {
          name: this.state.name,
          description: this.state.description,
          price: this.state.price,
          currency: "$",
          category_id: this.state.category_id,
          image: this.state.profileImageData
        }
      }
    };
    // const data = {
    //   attributes: attrs,
    // };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addpostApiCallId = requestMessage.messageId;
    // const httpBody = {
    //   data: data,
    // };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postGetUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(attrs)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getAllCategory() {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    console.log("requestMessage, ", requestMessage);

    this.apiGetCategoryCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllCatergoryEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostApiMethodType
    );
    //console.log('requestMessage@, ',requestMessage);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  tagPostCreationApi = (currentWord : string)=> {  
    const header = {
    "Content-Type": "application/json",
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postCreationApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.tagPostsEndpoint(currentWord)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return this.postCreationApiCallId;
  }
  getPostData(): boolean {
    console.log("getPostToken ", this.state.token);
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token
    };
    console.log("header  ", JSON.stringify(header));

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    console.log("requestMessage, ", requestMessage);

    this.apiPostItemCallId = requestMessage.messageId;
    console.log(
      "requestMessage, ",
      getName(MessageEnum.RestAPIResponceEndPointMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostApiMethodType
    );
    //console.log('requestMessage@, ',requestMessage);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  updateCreatePostData(Id: any) {
   
      const header = {
        "Content-Type": configJSON.postContentType,
        token: this.state.token
      };
      const attrs = {
        data: {
          attributes: {
            name: this.state.name,
            description: this.state.description,
            price: this.state.price,
            currency: "$",
            category_id: this.state.category_id,
            image: this.state.profileImageData
          }
        }
      };
      // const data = {
      //   attributes: attrs,
      // };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.updatePostApiCallId = requestMessage.messageId;
      // const httpBody = {
      //   data: data,
      // };
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.postGetUrl + "/" + `${Id}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(attrs)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.patchPostAPiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    
  }

  deleteRecord(Id: any) {
    Alert.alert(
      "Warning",
      "Are you sure for delete this post?",
      [
        { text: "No", onPress: () => {}, style: "cancel" },
        {
          text: "Yes"
        }
      ],
      { cancelable: false }
    );
  }

  delete(Id: any) {
    this.setState({ refresh: true });
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.DeleteApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postGetUrl + "/" + `${Id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deletePostAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.getPostData();
    return true;
  }







  chooseImage = () => {
    this.showAlert("Error", "Image Picker Not Implemented")  
  };

  valueExtractor1 = (val: { data: { attributes: { id: any } } }): any => {
    return val.data.attributes.id;
  };

  onValueHanndler = (val: { data: { attributes: { name: any } } }): any => {
    return val.data.attributes.name;
  };

  handleCatChange = (selectedOption: any) => {
    this.setState({ selectedCategory: selectedOption, category_id: selectedOption.value });
  };

  // UI interaction handlers
  handleCategoryChange = (e:any, option: any) => {
    const catSet = new Set(option.map((o:any)=>parseInt(o.id)))
    if(this.state.selectedCategory.length > option.length) { // remove option logic
      // check if atleast one category from each community exists
      const communities = this.state.selectedCommunities
      for(let i =0;i<communities.length;i++) {
        const com = communities[i]
        const comCatIds = com.category_ids.filter((id: any)=> catSet.has(id))
        if(comCatIds.length === 0 ) {
          toast.warn("Atleast one Category from each community required")
          return 
        }
      }
    }
    // udpate subCategories options
    {
      let subCategories
      let ids 
      // if(this.state.currentPost) {
      //   subCategories = this.state.currentPost.Sub_category_ids.map((id: any)=> this.state.allSubCategories.find((st: any)=>st?.id == id))
      // }
      // else {
        // get subcategories for all the currently selected communities
        ids = this.state.selectedCommunities.map((com: any) => com.id)
        subCategories = this.catsForCommunities(ids).subCategories
         
      // }

      // now filter subCategories with selected category options
      subCategories = subCategories.filter((st: any) => {
        const categories = st.attributes.categories || st?.categories
        if(!categories) return false
        return categories.some((ct: any) => catSet.has(parseInt(ct.id)))
      }).map((st: any) => (st) )
      console.log({ handleCategoryChange:'', subCategories, option, line: '627',ast: this.state.allSubCategories, st: this.state.subCategories, s: this.state })

      this.setState({ subCategories })
    }
    if(option && option.length) {
          const lookup = new Set(option.map((o:any)=>o.id))
          const selectedSubCategory = this.state.selectedSubCategory.filter((sc: any)=>{
            return sc.categories.some((ct:any)=>lookup.has(ct.id))
          })
         this.setState({ 
            selectedCategory: option || [], selectedSubCategory 
          })
          //, ()=>this.filterSubCategories()) 
          // this.apiGetSubCategoriesCallId = this.sendGetRequest(configJSON.getSubCategoriesEndpoint.replace(':id', option[0].id))
    } else {
      this.setState({ selectedCategory: [],selectedSubCategory:[], subCategories: []})
    }
  }

  handleSubCategoryChange = (e:any, option: any) => {
    if(this.state.selectedCategory.length > option.length) {
      const catSet = new Set(option.map((st: any) => st.id))
      const communities = this.state.selectedCommunities
      for(let i=0;i<communities.length;i++) {
        const com = communities[i]
        const comStIds = com.Sub_category_ids.filter((st: any)=>catSet.has(st))
        if(comStIds.length === 0){
          toast.warn("Atlease one Sub-Category from each community required.")
          return
        }
      }
    }
    this.setState({ selectedSubCategory: option || "" })
  }

  handleCommunityChange = (e:any, option: any) => {
    // this.setState({ selectedCommunities: option || [] })
  }
  
  // given ids of communities it returns, categories and sub categories
  // and categories options and subcategory options
  // selectedCommunitiesBackup is used if selectes communities from post detail API is not yet set into the state
  catsForCommunities = (ids: number[], selectedCommunitiesBackup?: any[]) => {
    const { communities, categories, allSubCategories } = this.state
    

    const selectedCommunities: any[] = []
    ids.forEach(id => {
      let com = communities.find((com: any)=>com.id == id)
      // try searching in already selected coms
      if(!com) com = this.state.selectedCommunities.find((com: any)=>com.id == id)
      if(!com && !!selectedCommunitiesBackup) com = selectedCommunitiesBackup.find((com: any)=> com.id == id)
      if(com) selectedCommunities.push(com)
    })
    
    const selectedCategory: any[] = []
    const categoriesOptions: any[] = []
    const categoryLookup = new Set<any>()
    // (this.state.selectedCategory.map(
    //     (cat: any) => cat.id)
    //   )
    const selectedSubCategory: any[] = []
    const subCategoryLookup = new Set<any>()
    // (this.state.selectedSubCategory.map(
    //     (st: any) => st.id)
    //   )
    const subCategories: any[] = []
    //console.log("line702", selectedCommunities)
    // add categories of each community into selectedCategory
    // also subCatogies into seelctedSubCategories
    selectedCommunities.forEach((com: any) => {
      if(Array.isArray(com.category_ids))
      (com.category_ids||[]).forEach((id: any) => {
        if(categoryLookup.has(id)) return
        categoryLookup.add(id)
        const cat = categories.find((cat: any) => cat.id == id)
        categoriesOptions.push(cat)
        selectedCategory.push(cat)
      })
      if(Array.isArray(com.Sub_category_ids))
      {
       // console.log({ handleBeforeError: com })
        com.Sub_category_ids.forEach((id: any) => {
          if(subCategoryLookup.has(id)) return
          subCategoryLookup.add(id)
          const cat = allSubCategories.find((st: any)=>st.id == id)
          if(!cat) return
          const st = cat.attributes
          selectedSubCategory.push(st)
          subCategories.push(st)
        })
      }
    })
    return {
      selectedCommunities, 
      selectedCategory,  
      subCategories, 
      categoriesOptions,
      selectedSubCategory
    } 
  }

  // create union of two arrays with an object's id as unique identifier
  arrayUnion = (arr: any, brr: any) => {
    const lookup = new Set(arr.map((a: any) => a.id))
    const ret = [...arr]
    brr.forEach((b: any)=>{
      if(!lookup.has(b.id)){
        lookup.add(b.id)
        ret.push(b)
      }
    })
    return ret
  }

  // create intersection of two arrays with object's id 
  arrayIntersection = (arr: any, brr: any) => {
    const lookup = new Set(arr.map((a: any)=>a.id))
    const ret: any[] = []
    brr.forEach((b: any) => {
      if(lookup.has(b.id)) ret.push(b)
    })
    return ret 
  }

  handleCommunityChangeEffect = (ids: number[]) => {
    let selectedCategory: any = []
    let selectedSubCategory: any = []
    if(this.state.selectedCommunities?.length <ids.length){
      // communities are added, we need (already selected) ∪ (new ids)
      // i.e. only select a cat or subCat if
      // - it was already selected
      // - it belongs to the newly selected community 
      const selectedComIds = new Set(this.state.selectedCommunities.map((com: any)=>com.id))
      const newComIds = ids.filter((id: any) => !selectedComIds.has(id))
      const val = this.catsForCommunities(newComIds)
      selectedCategory = this.arrayUnion(this.state.selectedCategory, val.selectedCategory)
      selectedSubCategory = this.arrayUnion(this.state.selectedSubCategory, val.selectedSubCategory)
    } else{
      // communities are removed, we need (Remaining) ∩ (already selected)
      // i.e. only keep a cat or subCat if 
      // - it was previously selected
      // - it is still selected, i.e. belongs to current selected communities
      const selectedComIds = new Set(this.state.selectedCommunities.map((com: any)=>com.id))
      const remainingComIds = ids.filter((id: any)=>selectedComIds.has(id))
      const val = this.catsForCommunities(remainingComIds)
      selectedCategory = this.arrayIntersection(this.state.selectedCategory, val.selectedCategory)
      selectedSubCategory = this.arrayIntersection(this.state.selectedSubCategory, val.selectedSubCategory)
    }
    
    const {
      selectedCommunities, 
      subCategories, 
      categoriesOptions,
    } = this.catsForCommunities(ids)
    

    // this.handleCategoryChange(null, selectedCategory)
    console.log({ selectedCommunities, selectedCategory, selectedSubCategory, handleCategoryChange: '' })
    this.setState({ selectedCommunities, subCategories, categoriesOptions, selectedCategory,  }, ()=>{
      // this.handleCategoryChange(null, selectedCategory)
      this.setState({ selectedSubCategory,  })
    })
  }
  handleSuggestionClick = (suggestion : {first_name:string},suggestion2: {last_name:string}) => {
    const { postText, cursorPosition } = this.state;
    const beforeCursor = postText.slice(0, cursorPosition).replace(/@[^@\s]*$/, "");
    const afterCursor = postText.slice(cursorPosition);
    const newText = `${beforeCursor}@${suggestion?.first_name} ${suggestion2?.last_name} ${afterCursor}`;

    this.setState({ postText: newText, tagPostData: [], showSuggetion: false ,
      cursorPosition: beforeCursor.length + suggestion?.first_name?.length + suggestion2?.last_name?.length + 2,
    },
    () => {
      const textField = document.getElementById("postTextField") as HTMLInputElement;
      if (textField) textField.setSelectionRange(this.state.cursorPosition, this.state.cursorPosition);
    }
  );
  };

   handlePostTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value, selectionStart } = e.target; 
      this.setState({ postText: value, cursorPosition: selectionStart ?? 0 });
    const lastAtMatch = e.target.value.match(/@([^\s]*)$/)
    if (lastAtMatch) {
      const currentWord = lastAtMatch[1];
      this.tagPostCreationApi(currentWord)
      }
   }
  handleCloseModal = () => {
    this.setState({ showModal: false })
    this.props.navigation.goBack()
  }
  isPostButtonEnabled = () => {
    const { selectedCategory, selectedSubCategory, postText, submittingPost } = this.state;
    const trimmedText = postText.trim();
    const isTextValid = trimmedText.length > 0 && trimmedText.length < 1001;
    const isCategorySelected = selectedCategory.length > 0;
    const isSubCategorySelected = selectedSubCategory.length > 0;
  
    return isTextValid && isCategorySelected && isSubCategorySelected && !submittingPost;
  };
  

  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    const files = Array.from(event.target.files || [])
    const size = this.state.selectedFiles.map(file=>file.size).reduce((a: any, b:any)=>a+b, 0)
    const newFilesSize = files.map(file => file.size).reduce((a: any, b: any) => a+b, 0)
    const maxSize = 20*1024*1024
    if(size+newFilesSize>maxSize)
    {
      this.setState({ fileSizeWarning: "Total size of all files cannot be more than 20Mb" })
      return ;
    }
    if (event.target.files) 
      this.setState({ selectedFiles: this.state.selectedFiles.concat(files) })
  }

  handleFileRemove = (e: React.MouseEvent<HTMLButtonElement>) => {
    const filename = e.currentTarget.dataset.filename;
    this.setState({ selectedFiles: this.state.selectedFiles.filter(f => f.name !== filename), fileSizeWarning: '' })
  }

  handleAttachmentRemove = (e: React.MouseEvent<HTMLButtonElement>) => {
    const url = e.currentTarget.dataset.filename;
    const currentPost = this.state.currentPost
    if(!currentPost) return
    const attachments = this.state.currentPost.images_and_videos
      .filter((file: any)=> file.filename!==url)
    this.setState({ 
      currentPost: { ...currentPost, images_and_videos: attachments} 
    })

  }
  //
  filterSubCategories = () => {
  const { allSubCategories, selectedCategory } = this.state
    const selectedCatIds = selectedCategory.map((cat:any) => cat.id)
    
    const subCategories = allSubCategories
      .map((cat:any) => cat.attributes)
      .filter((scat: any) => selectedCatIds.includes(scat.categories[0]?.id))
    this.setState({ subCategories })
  }

  /**
  * Send PUT request to update the post
  */
  updatePost = (data: any, formdata: FormData) => {
    // this.state.currentPost.images_and_videos?.forEach?.(
    //   (file: any) => formdata.append('data[attributes][images_id][]', file.id)
    // )
    if(Array.isArray(this.state.currentPost.images_and_videos))
      formdata.append('data[attributes][images_id]', this.state.currentPost.images_and_videos?.map((file: any)=>file.id).join(',')) 
    else {
      // @ts-ignore
      formdata.append('data[attributes][images_id]', ' ')
    }
    const token = this.state.token
    this.apiUpdatePostCallId = sendAPIRequest(configJSON.updatePostEndpoint.replace(':id', this.state.currentPost.id), {
        method: 'PUT',
        body: formdata,
        headers: {token}
      })
    this.setState({ submittingPost: true })
    console.log({apiUpdatePostCallId: this.apiUpdatePostCallId})
  }

  createNewPost = (data: any) => {

    const { title: name, community_ids } = data
    
    const { postText,  selectedCommunities, selectedCategory, selectedSubCategory, selectedFiles } = this.state 
    const body = new FormData()
    body.append("data[attributes][body]", postText)
    body.append("data[attributes][name]", name);
    // body.append("data[attributes][community_id]", selectedCommunities[0].id)
    // body.append("data[attributes][category_id]", selectedCategory.id)
    // body.append("data[attributes][sub_category_id]", selectedSubCategory.id)
    if(!this.state.currentPost) {
        (community_ids||[]).forEach((com:any)=> body.append("data[attributes][post_communities_attributes][][community_id]", com))
        selectedCategory.forEach((cat:any)=> body.append("data[attributes][post_categories_attributes][][category_id]", cat.id))
        selectedSubCategory.forEach((cat:any)=> body.append("data[attributes][post_sub_categories_attributes][][sub_category_id]", cat.id))
    }
    selectedFiles.forEach(file => body.append("data[attributes][images][]", file))
    
    if(this.state.currentPost) {
      body.append("data[attributes][post_communities_attributes][][community_id]", (community_ids||[]).map((com: any)=>com).join(','))
      body.append("data[attributes][category_id]", selectedCategory.map((cat: any)=>cat.id).join(','))
      body.append("data[attributes][post_sub_categories_attributes][][sub_category_id]", selectedSubCategory.map((cat: any)=>cat.id).join(',') )
      this.updatePost(data, body)
      return 
    }
    this.setState({ submittingPost: true })

    const headers = {
      "token": this.state.token,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.apiCreatePostCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createPostEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  // Generic method to send GET request 
  sendGetRequest = (endpoint: string, ) => {
    const headers = {
      "Content-Type": "application/json",
      "token": this.state.token,
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    const callId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return callId;
  }

  tokenInitialized = () => {
    this.apiGetAllCommunitiesCallId = this.sendGetRequest(configJSON.getAllCommunitiesEndpoint)
    this.apiGetAllCategoriesCallId = this.sendGetRequest(configJSON.getAllCategoriesEndpoint)
    this.apiGetAllSubCategoriesCallId = this.sendGetRequest(configJSON.getAllSubCategoriesEndpoint)
    this.fetchCurrentPost()
  }

  handleGetCommunitiesAPIResponse = (json: GetAllCommunitiesResponse) => {
    if(!json?.communities?.data) return
    let communities = json.communities.data.map(x => ({ id: Number(x.id), name: x.attributes.name, category_ids: x.attributes.category_ids, Sub_category_ids: x.attributes.Sub_category_ids }))
    if(this.state.currentPost && this.state.selectedCommunities.length>0)
      communities = [...this.state.selectedCommunities, ...communities]
    console.log({handle: 'communities', com: this.state.selectedCommunities, s: this.state})
    this.setState({ communities, communityPagination: json.meta.pagination })
  }

  validationSchema = Yup.object().shape({
    title: Yup.string().trim().required().label('Title').min(2).max(150),
    postText: Yup.string()
  })

  initialValues = {
    title: "",
    community_ids: []
  }


  handlePostCreatedResponse = (responseJson: any) => {
    if(responseJson.message){
      toast.error(responseJson.message)
      this.setState({ submittingPost: false })
    }else{
      const msg = new Message(
        getName(MessageEnum.NewPostCreatedMessage)
      )
      msg.addData(getName(MessageEnum.NewPostCreatedDataMessage), responseJson)
      runEngine.sendMessage(msg.id, msg)
      this.setState({ submittingPost: false })
      toast.success("Post created successfully")
      this.handleCloseModal()
    }
  }


  handleCommunitySearch = (communityQuery: string) => {
    this.setState({ communityQuery }, this.searchCommunity)
  }
  
  searchCommunity = () => {
    const { token, communityQuery } = this.state
    const params = new URLSearchParams()
    params.append("query", communityQuery)
    const callId = sendAPIRequest(configJSON.getAllCommunitiesEndpoint+"filter/?"+params.toString(), {
      headers: { token },
    })
    this.setState({ 
      communitySearchCallId: callId, 
    })
  }

  paginateCommunitiesOptions = () => {
    const page = this.state.communityPagination.next_page
    const { token } = this.state
    if (!page) return
    const params = new URLSearchParams()
    params.append("page", page)
    let callId = '';
    if (this.state.communityQuery) {
      params.append("query", this.state.communityQuery)
      callId = sendAPIRequest(configJSON.getAllCommunitiesEndpoint + "filter/?" + params.toString(), {
        headers: { token },
      });
    } else {
      callId = sendAPIRequest(configJSON.getAllCommunitiesEndpoint + "?" + params.toString(), {
        headers: { token },
      });
    }


    this.setState({
      communityPaginateCallId: callId
    });
  }
     

  /**
  * Fetch current post, if this is an edit page
  */
  fetchCurrentPost = () => {
    const post_id = this.props.navigation.getParam('post_id')
    console.log({post_id})
    if(!post_id) return
    const token = this.state.token
    this.currentPostCallId = sendAPIRequest(configJSON.getPostDetailEndpoint.replace(':id', post_id), {
        headers: {token},
      method: 'GET'
        
      })
    this.setState({ loading: true })
  }

  setEditDefaultsCallback = () => {
    if(!this.state.categories?.length || !this.state.allSubCategories?.length)
      return 
       // set selectedCategory Block
        const selectedCategory: any[] = []
        const lookup = new Set(this.state.currentPost.category_ids)
        this.state.categories.forEach((cat: any) => {
          if(lookup.has(cat.id))
            selectedCategory.push(cat)
        })
       // console.log({selectedCategory, line: '856'})
        this.setState({ selectedCategory,  })
      
      //console.log({ handleSetEditDefault: '', cd: this.state.currentPost?.attributes?.community_details, s: this.state })
      let selectedCommunities = this.state.currentPost.community_details.data.map((com: any)=>({id: parseInt(com.id), ...com.attributes}))
      // this.setState({ initCommunities: selectedCommunities })

         // set selectedSubCategory Block
          const selectedSubCategory: any[] = []
          const lookup2 = new Set(this.state.currentPost.Sub_category_ids)
          this.state.allSubCategories.forEach(
            (cat: any) => {
              if(!cat || !cat?.attributes) return
              if(lookup2.has(cat.attributes.id)) 
                selectedSubCategory.push(cat.attributes)
            }
          )
          this.setState({ selectedSubCategory,  })
        
        // update categoryOptions and subCategories according to community
          const ids = selectedCommunities.map((com: any)=>com.id)
          const { 
            subCategories, 
            categoriesOptions,
          } = this.catsForCommunities(ids, selectedCommunities)
         // console.log({ line1091: "", subCategories, categoriesOptions, sc: this.state.selectedCommunities })
          this.setState({ selectedCommunities, subCategories, categoriesOptions })
        
     
      this.setState({ loading: false })
      clearInterval(this.setEditDefaultIntervalId);
  }

  setEditDefaults = () => {
    if(!this.state.currentPost) return ;
    this.setEditDefaultIntervalId = setInterval(this.setEditDefaultsCallback, 500);
  }

  handleRestAPIResponseMessage(message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    runEngine.debugLog("API Message Received", message);

    if (responseJson && responseJson.errors) {
      this.setState({ refresh: false });
      this.parseApiErrorResponse(responseJson.errors);
      this.parseApiCatchErrorResponse(responseJson.errors);
    } else if (responseJson) {
      switch (apiRequestCallId) {
        case this.apiGetAllCommunitiesCallId:
          this.handleGetCommunitiesAPIResponse(responseJson);
          break;

        case this.state.communityPaginateCallId:
          this.handleCommunityPaginateAPIResponse(responseJson);
          break;

        case this.state.communitySearchCallId:
          let communities: any = [];
          if (Array.isArray(responseJson.data)) {
            communities = responseJson.data.map((c: any) => c.attributes);
            this.setState({
              communitySearchCallId: "",
              communityPagination: responseJson.meta.pagination,
              communities
            });
          }else{
            this.setState({
              communitySearchCallId: "",
              communityPagination: undefined,
              communities
            });
          }
          
          break;

        case this.apiGetAllSubCategoriesCallId:
          this.setState({ allSubCategories: responseJson.data || [] });
          break;

        case this.apiGetAllCategoriesCallId:
          this.handleGetAllCategoriesResponse(responseJson);
          break;

        case this.apiGetSubCategoriesCallId:
          this.handleGetSubCategoriesResponse(responseJson);
          break;

        case this.apiCreatePostCallId:
          this.handlePostCreatedResponse(responseJson);
          break;

        case this.apiPostItemCallId:
          this.setState({ PostData: responseJson, refresh: false });
          break;

        case this.addpostApiCallId:
          this.setState({ refresh: true });
          this.props.navigation.state.params.callback();
          break;

        case this.apiGetCategoryCallID:
          this.handleGetCategoryResponse(responseJson);
          break;

        case this.DeleteApiCallId:
          this.getPostData();
          break;

        case this.currentPostCallId:
          this.setState(
            {
              currentPost: responseJson.data.attributes,
              postText: responseJson.data.attributes.body
            },
            () => this.setEditDefaults()
          );
          break;

        case this.apiUpdatePostCallId:
          this.setState({ submittingPost: false });
         
            sendPostUpdatedMessage(responseJson.data.attributes);
          
          this.props.navigation.goBack();
          break;

        default:
          break;
      }
    } else if (errorReponse) {
      this.setState({ refresh: false });
      this.parseApiErrorResponse(errorReponse);
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }


  handleCommunityPaginateAPIResponse(responseJson: any) {
    const communitie = [];
    const communityData = responseJson.communities
      ? responseJson.communities.data
      : null;
    const responseData = responseJson.data;
  
    if (Array.isArray(communityData) || Array.isArray(responseData)) {
      if (this.state.communityQuery && responseData.length>0) {
        communitie.push(...responseData.map((c: any) => c.attributes));
      } else {
        communitie.push(...communityData.map((c: any) => c.attributes));
      }
  
      this.setState({
        communityPaginateCallId: "",
        communityPagination: responseJson.meta.pagination,
        communities: [...this.state.communities, ...communitie],
      });
    }
  }

  handleGetAllCategoriesResponse(responseJson: any) {
    let categories: any[] = [];
    if (Array.isArray(responseJson)) {
      categories = responseJson;
    } else if (Array.isArray(responseJson.data)) {
      categories = responseJson.data.map((ct: any) => ct.attributes);
    }
    this.setState({ categories });
  }

  handleGetSubCategoriesResponse(responseJson: any) {
    let subCategories: any[] = [];
    if (Array.isArray(responseJson)) {
      subCategories = responseJson;
    } else if (Array.isArray(responseJson.data)) {
      subCategories = responseJson.data;
    }
    this.setState({ subCategories });
  }

  handleGetCategoryResponse(responseJson: any) {
    let allCategories: any[] = [];
    let categorie: string[] = [];
  
    const data = responseJson;
  
    data.forEach((item: any) => {
      if (categorie.indexOf(item.data.attributes.name) === -1) {
        let category = {
          value: item.data.attributes.id,
          label: item.data.attributes.name,
        };
        allCategories.push(category);
        categorie.push(item.data.attributes.name);
      }
    });
  
    this.setState({
      AllCategory: responseJson,
      allCategories: allCategories,
    });
    this.getPostData();
  }

  // Customizable Area End
}
